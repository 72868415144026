import Logout from "./logout";
import "./navbar.css";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import Logo from "../logo";
import NavbarVoice from "./navbarVoice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Navbar(){ 

    const { user, setUser } = useContext(UserContext)
    const navigate = useNavigate()
    const { t } = useTranslation()

    function logout(){
        setUser(null)
        navigate("/login")
    }

    const userMenu = <div className="dropdown Ynfg__navbar-user-menu">
        <button className="dropdown-toggle" type="button" id="dropdownMenuUser" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa fa-user-circle" />&nbsp;{user.username} 
        </button>
        <ul className="dropdown-menu text-capitalize" aria-labelledby="dropdownMenuUser">
            <li>
                <a className="dropdown-item" href="#" onClick={() => {logout()}}>
                    <i className="fa fa-sign-out" />{t('buttons.logout')}</a>
            </li>
        </ul>
    </div>

    return (
        <nav className="navbar navbar-expand-lg Ynfg__navbar">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <Logo />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/*<NavbarVoice 
                            className="nav-link active Ynfg__navbar-link" 
                            aria-current="page" 
                            to="/" 
                        label="Calendario"/>*/}
                    </ul> 
                    <div className="d-flex">
                        { userMenu }
                    </div>
                </div>
            </div>
        </nav>
    )
}