import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import UserContextProvider from './core/context/UserContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Calendar from './routes/calendar';
import Login from './routes/login';

function App() {
  return (
    <BrowserRouter>
        <div className="Ynfg__app">
          <UserContextProvider> 
            <Routes>
            
                <Route path="/" element={<Calendar />} />
                <Route path="/login" element={<Login />} />                                
            
            </Routes>
          </UserContextProvider>

        </div>
    </BrowserRouter>
  );
}

export default App;
