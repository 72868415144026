import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import '../../../style/components/form.css';
import VehicleType from '../domain/vehicleType';
import { VEHICLE_SERVICE } from '../service/vehicleService';
import { EVENT_SERVICE } from '../service/eventService';
import { COMPARE_TIME, CONVERT_DATE_OBJ_TO_ISO_LOCAL, GET_END_TIME, IS_BOUND_LIMITED, IS_RANGE_FREE } from '../utils/calendarUtils';
import Message from '../../../core/components/layout/message';
import { useTranslation } from 'react-i18next';

export default function CalendarForm({ dto, readonly = false , afterSubmit, dailyEvents }) {

    const formikRef = useRef(null);
    const [initial, setInitial] = useState(dto)
    const [vehicleTypes, setVehicleTypes] = useState([]) 
    const { t } = useTranslation()

    const validateFormState = ((values) => {
        const errors = {}
        if(!values.plate){
            errors['plate'] = t('form.plate_empty')
        }
        if(!values.packages){
            errors['packages'] = t('form.packages_empty')
        }else{
            if(values.packages <= 0 || values.packages > 999999)
                errors['packages'] = t('form.packages_wrong')
        }
        if(!values.dateStart){
            errors['dateStart'] = t('form.startDate_empty')
        }
        if(!values.timeStart){
            errors['timeStart'] = t('form.startTime_empty')
        }
        if(!values.timeEnd){
            errors['timeEnd'] = t('form.endTime_empty')
        }
        if(!COMPARE_TIME(values.timeStart, values.timeEnd)){
            errors['timeEnd'] = t('form.endTime_wrong')
        }
        if(dailyEvents){
            let rangeOfDaySelected = dailyEvents.filter(de => de && CONVERT_DATE_OBJ_TO_ISO_LOCAL(de.dateStart) == values.dateStart)
                                                .map(de => {
                                                    return {
                                                            start: de.timeStart,
                                                            end: de.timeEnd
                                                    }
                                                })
            if(!IS_RANGE_FREE(rangeOfDaySelected, values.timeStart, values.timeEnd)){
                errors['range'] = t('form.timeRange_busy')
            }
        }
        if(!IS_BOUND_LIMITED(values.timeStart, values.timeEnd)){
            errors['range'] = t('form.timeRange_boundErr')
        }
        return errors;
    })

    const onSubmit = (values, { setSubmitting }) => {
        console.log(values)
        EVENT_SERVICE.saveNew({...values}).then(resp => {
            console.log(resp)
            afterSubmit(resp)
        })
    }

    const calculateDateEnd = (reload) =>{
        if(formikRef.current && formikRef.current.values){
            formikRef.current.setFieldValue("timeEnd", GET_END_TIME(formikRef.current.values.timeStart, formikRef.current.values.packages, reload))
        }
    }

    useEffect(() => {
        VEHICLE_SERVICE.getVehicleTypes().then(resp => {
            let vts = []
            Array.from(resp.data).forEach(vt => {
                vts.push(new VehicleType(vt))
            })
            setVehicleTypes(vts)
        })
        console.log("occupied slots: ",dailyEvents)
    },[dailyEvents])

    /**
     * Load init obj when passed change
     */
    useEffect(() => {
        if(dto && dto.timeStart && dto.packages && !dto.timeEnd)
            dto.timeEnd = GET_END_TIME(dto.timeStart, dto.packages, false)
        setInitial(dto)
    },[dto])

    return (
        <div className="Ynfg__generic-form container">
            <h1 className='Ynfg__title mb-4'>{t('form.reservation')}</h1>
            {initial && <Formik initialValues={initial} 
                    validate={validateFormState}
                    onSubmit={onSubmit}
                    innerRef={formikRef}
                    enableReinitialize={true}>
                { ({touched, errors}) => (
                <Form className='form row'>
                    {
                        errors.range && <Message logLevel={"danger"} text={errors.range} />
                    }
                    <div className='form-group col-md-8'>
                        <label htmlFor="idVehicleType">{t('form.vehicleType')}</label>
                        <Field name="idVehicleType" 
                               as="select" 
                               className="Ynfg__form-field form-control"
                               disabled = {readonly}>
                            {
                                vehicleTypes.map((vt, index) => {
                                    return <option key={index} value={vt.id}>{vt.description}</option>
                                })
                            }
                        </Field>
                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor="plate">{t('form.plate')}</label>
                        <Field type="text" 
                               name="plate"
                               className="Ynfg__form-field form-control" 
                               required
                               disabled = {readonly}/>
                        {
                            touched.plate && <ErrorMessage name="plate" component="div" className="invalid-feedback"/>
                        }
                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor="packages">{t('form.packages')}</label>
                        <Field type="number"
                               step="1" 
                               name="packages" 
                               className="Ynfg__form-field form-control" 
                               required
                               onChange={(e) => { 
                                    formikRef.current.setFieldValue("packages",e.target.value).then(resp => {
                                        calculateDateEnd(formikRef.current.values.load) 
                                    })
                                }} 
                               disabled = {readonly}/>
                        {
                            touched.packages && <ErrorMessage name="packages" component="div" className="invalid-feedback"/>
                        }
                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor="notes">{t('form.notes')}</label>
                        <Field component="textarea" 
                               name="notes" 
                               className="Ynfg__form-field form-control"
                               disabled = {readonly}/>
                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor="dateStart">{t('form.startDate')}</label>
                        <Field type="date" 
                               name="dateStart"
                               className="Ynfg__form-field form-control"
                               required
                               disabled = {readonly}/>
                        {
                            touched.dateStart && <ErrorMessage name="dateStart" component="div" className="invalid-feedback"/>
                        } 
                    </div>
                    <div className='form-group form-check form-check col-md-4'>
                        <label htmlFor="notes">{t('form.load')}</label>
                        <Field type="checkbox" 
                               name="load" 
                               className="Ynfg__form-field-checkbox form-check-input"
                               onChange={(e) => {
                                formikRef.current.setFieldValue("load",e.target.checked)
                                calculateDateEnd(e.target.checked) 
                               }}
                               disabled = {readonly}/>
                    </div>
                    
                    <div className='form-group col-md-4'>
                        <label htmlFor="timeStart">{t('form.startTime')}</label>
                        <Field type="time" 
                               name="timeStart" 
                               className="Ynfg__form-field form-control"
                               required
                               onKeyUp={() => { calculateDateEnd() }}
                               disabled = {readonly}/>
                        {
                            touched.timeStart && <ErrorMessage name="timeStart" component="div" className="invalid-feedback"/>
                        } 
                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor="timeEnd">{t('form.endTime')}</label>
                        <Field type="time" 
                               name="timeEnd" 
                               className="Ynfg__form-field form-control"
                               required
                               disabled = {true}/>
                        {
                            touched.timeEnd && <ErrorMessage name="timeEnd" component="div" className="invalid-feedback"/>
                        } 
                    </div>
                    {   !readonly &&
                        <button type="submit" disabled={Object.keys(errors).length > 0} className='btn Ynfg__form-button'>
                            {t('form.submit')}
                        </button>
                    }
                </Form>
                )}
            </Formik>}
        </div>
    )

}