export default class VehicleType{

    id
    description
    color

    constructor(vt){
        if(vt){
            this.id = vt.id
            this.description = vt.description 
            this.color = vt.color
        }
    }
}