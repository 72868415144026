import axios from "axios"

export default class GenericService {

    _baseUrl = ''
    _axios = undefined

    constructor(url){
        if(url){
            this._baseUrl = url
            this._axios = axios.create()
        }
    }

    get axios () {
        return this._axios
    }

    appendToBaseUrl(url, option){ 
        return `${this._baseUrl}${url}` 
    } 

    static buildQueryString = (queryArray) => {
        let queryString = '?'
        queryArray.forEach((element,index) => {
            queryString += `${element.name}=${element.value}`
            if(index < (queryArray.length - 1) )
                queryString += '&' 
        });
        return queryString
    }
}

export class QueryString {
    name
    value

    constructor(name, value){
        this.name = name
        this.value = value
    }
}