import { useContext, useEffect, useRef, useState } from 'react'
import '../style/weeklyCalendar.css'
import { CONVERT_DATE_OBJ_TO_ISO_LOCAL, GET_DATE_NEXT_WEEK, GET_DATE_WEEK, GET_HOURS_ARRAY, GET_PREV_WEEK, GET_WEEK_NO, WEEK_DAYS } from '../utils/calendarUtils';
import { CalendarGridSystem, HOURS_PORTIONS } from '../utils/calendarGridSystem';
import CalendarForm from './calendarForm';
import EventDto from '../dto/eventDto';
import { BModal } from '../../../core/components/layout/bmodal';
import Event from '../domain/event';
import { UserContext } from '../../../core/context/UserContext';
import { useTranslation } from 'react-i18next';

export default function WeeklyCalendar({ timeslotBegin , timeslotEnd , loadFunct , pollingFunct , deleteFunct }){

    const userCtx = useContext(UserContext);
    const [weekStruct, setWeekStruct] = useState({
        range: GET_DATE_WEEK(new Date()),
        array: WEEK_DAYS(new Date()),
        weekNo: GET_WEEK_NO(new Date()),
        hours: GET_HOURS_ARRAY(timeslotBegin, timeslotEnd),
        events: []
    })
    const [formModel, setFormModel] = useState(null)
    const eventContainer = useRef(null)
    const { t, i18n } = useTranslation()

    const calendarGrid = new CalendarGridSystem(timeslotBegin, timeslotEnd)
    const POLLING_REQ_TIMEOUT = 51000
    const THIS_YEAR = new Date().getFullYear()
    const THIS_MONTH = new Date().getMonth() + 1 


    function loadEventOfPeriod(year, month){
        loadFunct(year,month).then(resp =>{
            if(resp){
                var evs = []
                Array.from(resp.data).forEach(element => {
                    evs.push(new Event(element))
                });
                setWeekStruct((prev) => {
                    return {
                        ...prev,
                        events: [...evs.filter(e => e.dateStart >= prev.range.startDay && e.dateStart <= prev.range.endDay)]
                    }
                })
            }
        }).catch(error => {
            console.log("error retrieving calendar events: ",error)
        })
    }

    function pollUpdate(year, month, timer){
        pollingFunct(year,month).then(resp =>{
            if(resp && resp.status === 201){
                var evs = []
                Array.from(resp.data).forEach(element => {
                    evs.push(new Event(element))
                });
                setWeekStruct((prev) => {
                    return {
                        ...prev,
                        events: [...evs.filter(e => e.dateStart >= prev.range.startDay && e.dateStart <= prev.range.endDay)]
                    }
                })
            }else if(resp && resp.status === 200){
                //nothing new..
                console.log("polled with nothing new, restart polling")
            }
            if(timer)
                clearTimeout(timer)
            let _timer = setTimeout(() => { pollUpdate(year, month, _timer) } ,POLLING_REQ_TIMEOUT)
        }).catch(error => {
            console.log("error retrieving calendar events: ",error)
        })
    }

    function deleteEvent(event){
        deleteFunct(event).then(resp => {
            loadEventOfPeriod(THIS_YEAR, THIS_MONTH)
        })
    }

    /**
     * load events at the beginning
     */
    useEffect(() => {
        loadEventOfPeriod(THIS_YEAR, THIS_MONTH) 
        pollUpdate(THIS_YEAR, THIS_MONTH)
    },[])

    /**
     * print current state
     */
    useEffect(() => {
        console.log(weekStruct)
    },[weekStruct])

    function addEvent(row, col){
        setFormModel(new EventDto(weekStruct.array[col], calendarGrid.getTimeByRow(row)))
    }

    function loadInfoEvent(event){
        let currentEventDto = new EventDto(null, null)
        currentEventDto.buildFromDomainObj(event)
        setFormModel(currentEventDto)
    }

    function nextWeek(){
        let _nw = GET_DATE_NEXT_WEEK(weekStruct.range.startDay)
        setWeekStruct((prev) => {
            return {
                range: _nw,
                array: WEEK_DAYS(_nw.startDay),
                weekNo: GET_WEEK_NO(_nw.startDay),
                hours: prev.hours,
                events: []
            }
        })
        loadEventOfPeriod(THIS_YEAR, THIS_MONTH)
    }

    const prevWeek = () =>{
        let _pw = GET_PREV_WEEK(weekStruct.range.startDay)

        setWeekStruct((prev) => {
            return {
                range: _pw,
                array: WEEK_DAYS(_pw.startDay),
                weekNo: GET_WEEK_NO(_pw.startDay),
                hours: prev.hours,
                events: []
            }
        })
        loadEventOfPeriod(THIS_YEAR, THIS_MONTH)
    }

    return (
        <div className='calendar-container'>
            <div className='pagination'>
                <div className='pagination-content'>
                    <div className='navigation'>
                        <button type='button'
                                className='btn Ynfg__common-btn' 
                                onClick={() => prevWeek()}>
                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
                        </button>
                            <span className='week'>
                                {t('calendar.week')}: {weekStruct.weekNo}
                            </span>
                        <button type='button'
                                className='btn Ynfg__common-btn' 
                                onClick={() => nextWeek()} >
                            <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className='range'>
                        <p>
                        {weekStruct.range.startDay.toLocaleDateString(i18n.language)} - 
                        {weekStruct.range.endDay.toLocaleDateString(i18n.language)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-calendar-container">        
                <div className="header">
                    <div className="weekdays">
                        <ul>
                            {
                                weekStruct.array.map((day, index) => {
                                    return <li key={index}>
                                        <span>
                                            {day.toLocaleDateString(i18n.language, { weekday: 'long' })}
                                        </span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="weekdayNo">
                        <ul>
                            {
                                weekStruct.array.map((day, index) => {
                                    return <li key={index}>
                                        <span>
                                            {day.getDate()}/{day.toLocaleString(i18n.language, { month: 'long' })}
                                        </span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="timeslots-container">
                    <div className="timeslots">
                        <ul>
                            {
                                weekStruct.hours.map((h, index) => {
                                    return <li key={index}>
                                        <label>
                                            {h}
                                        </label>
                                    </li>
                                })
                            }
                        </ul>
                    </div>      
                </div>

                <div className="event-container" ref={eventContainer}>
                    {
                        weekStruct.array.map((day, col) => {
                            console.log("Day of week",day.getDay())
                            let isWeekend = !(day.getDay() > 0 && day.getDay() < 6)
                            let dayCssClass = !isWeekend ? 'daygrid' : 'daygrid dayDisabled'
                            return <div className={dayCssClass} 
                                        key={col} 
                                        style={{
                                            display: 'grid',
                                            gridTemplateRows: `repeat(${calendarGrid.getRowsPerDay()}, 1fr)`,
                                            gridColumn: `${col + 1 }` ,
                                            gridRow: `1 / ${calendarGrid.getRowsPerDay() + 1}`
                                        }}>
                                {
                                    !isWeekend && [...Array(calendarGrid.getRowsPerDay()).keys()].map((portionOfHour,row) => {
                                        return <div className='hourGrid'
                                            key={row} 
                                            style={{
                                                gridRow: `${portionOfHour + 1}`
                                            }}
                                            onClick={() => { addEvent(row, col)}}
                                            data-bs-toggle="modal" 
                                            data-bs-target="#eventModal">
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                    {//responsive hours
                        weekStruct.array.map((day, col) => {
                            return weekStruct.hours.map((h, index) => {
                                return <div key={index} className={'responsive-hours d-md-none'} style={{
                                    width: `55px`,
                                    opacity: 0.5,
                                    gridColumn: `${col + 1}`,
                                    gridRow: `${index * HOURS_PORTIONS + 1}`,
                                    height: `55px`,
                                    position: 'absolute',
                                    left: `2px`
                                }}>
                                    <label>
                                        {h}
                                    </label>
                                </div>
                            })
                        })
                    }
                    {
                        weekStruct.events.map((e, key) => {
                            return <div className='slot' key={key} 
                                 style={{
                                            backgroundColor: e.vehicleType.color,
                                            width: `${(eventContainer.current.offsetWidth / 7 * 95) / 100}px`,
                                            transform: `translateX(${2.5}%)`,
                                            gridColumn: calendarGrid.gridColumn(e.dateStart),
                                            gridRow: calendarGrid.gridRow(e.timeStart),
                                            height: CalendarGridSystem.durationInPixels(e.timeStart, e.timeEnd)
                                        }}
                                    >
                                <div className='slot-header'>
                                    <label>{e.user.username}</label>
                                    <a 
                                        onClick={() => {
                                            console.log('load infos...')
                                            loadInfoEvent(e)
                                        }}
                                        data-bs-toggle="modal" 
                                        data-bs-target="#eventInfoModal">
                                        <i className="fa fa-info" aria-hidden="true"></i>
                                    </a>
                                    {userCtx.user && (userCtx.user.isAdmin() || userCtx.user.username === e.user.username) &&
                                        <a 
                                            onClick={() => {
                                                console.log('load infos...')
                                                if(window.confirm("Sicuro di voler eliminare?") == true)
                                                    deleteEvent(e)
                                            }}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </a>
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                {
                    <BModal title={null} idModal={"eventModal"} ariaLabel={"ariaEventModal"} >
                        {<CalendarForm dto={formModel} 
                                       id={1} 
                                       afterSubmit={(resp) => {
                                            loadEventOfPeriod(THIS_YEAR, THIS_MONTH)
                                            document.getElementById('close-modal').click();
                                       }}
                                       dailyEvents={weekStruct.events}
                                        />}
                    </BModal>
                }
                {
                    <BModal title={null} idModal={"eventInfoModal"} ariaLabel={"ariaEventInfoModal"} >
                        {<CalendarForm dto={formModel} id={2} readonly={true}/>}
                    </BModal>
                }
            </div>
        </div>
    )
}