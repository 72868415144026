export default class Event{

    id
    dateStart
    timeStart
    dateEnd
    timeEnd
    plate
    vehicleType
    user
    packages
    load
    notes

    constructor(e){
        if(e){
            this.id = e.id
            this.dateStart = new Date(e.dateStart.toString())
            this.dateStart.setHours(0,0,0,0)
            this.timeStart = e.timeStart
            this.dateEnd = new Date(e.dateEnd.toString())
            this.dateEnd.setHours(0,0,0,0)
            this.timeEnd = e.timeEnd
            this.plate = e.plate
            this.vehicleType = e.vehicleType
            this.user = e.user
            this.packages = e.packages
            this.load = e.load
            this.notes = e.notes
        }
    }
}