import { CONVERT_DATE_OBJ_TO_ISO_LOCAL } from "../utils/calendarUtils"

export default class EventDto{

    dateStart
    timeStart
    dateEnd
    timeEnd
    plate
    idVehicleType
    packages
    load
    notes

    constructor(dateStart, timeStart){
        if(dateStart && timeStart){
   
            this.dateStart = CONVERT_DATE_OBJ_TO_ISO_LOCAL(dateStart) 
            this.dateEnd = CONVERT_DATE_OBJ_TO_ISO_LOCAL(dateStart)
            
            let hours = timeStart.toString().split(':')[0]
            let minutes = timeStart.toString().split(':')[1]
            if(hours < 10)
                hours = "0".concat(hours.toString())
            if(minutes < 10)
                minutes = "0".concat(minutes.toString())    
            this.timeStart = hours.concat(':').concat(minutes)
            this.timeEnd = ''
            this.plate = ''
            this.idVehicleType = 1
            this.packages = 1
            this.load = false
            this.notes = ''
        }else{
            console.debug('building a clean dto')
        }
    }

    buildFromDomainObj(event){
        this.dateStart = CONVERT_DATE_OBJ_TO_ISO_LOCAL(event.dateStart)
        this.dateEnd = CONVERT_DATE_OBJ_TO_ISO_LOCAL(event.dateEnd)  
        this.timeStart = event.timeStart
        this.timeEnd = event.timeEnd
        this.plate = event.plate
        this.idVehicleType = event.vehicleType.id
        this.packages = event.packages
        this.load = event.load
        this.notes = event.notes || ''
    }
}