import { Formik, Form, Field, ErrorMessage } from 'formik';
import { userCodeValidator } from '../../../core/common/utils';
import logo_img from '../../../assets/logo_small.png'
import '../../../style/components/form.css'
import { AUTH_SERVICE } from '../service/authService';
import { useContext } from 'react';
import { UserContext } from '../../../core/context/UserContext';
import i18n from '../../../core/i18n/i18n';
import { useTranslation } from 'react-i18next';

export default function LoginForm(props) {

    const userCtx = useContext(UserContext);
    const { t } = useTranslation()

    const initialFormState = {
        usercode: '',
        password: ''
    }

    const validateFormState = (values) => {
        const errors = {}
        if (!values.usercode) {
            errors.usercode = t('form.usercode_empty');
        }else if (!userCodeValidator(values.usercode)){
            errors.usercode = t('usercode_wrong');
        }
        if (!values.password) {
            errors.password = t('form.password_empty');
        }
        return errors;
    }

    const onSubmit = (values, { setSubmitting }) => {
        let dto = {
            username: values.usercode,
            password: values.password
        }
        AUTH_SERVICE.login(dto).then(resp => {
            let usr = resp.data.data
            userCtx.setUser(usr)
            i18n.changeLanguage(resp.data.data.lang.toLowerCase() || 'it')
        })
    }

    return (
        <div className="Ynfg__login-form container">
            <h1 className='Ynfg__title mb-4'>{t('form.login')}</h1>
            <Formik initialValues={initialFormState} 
                    validate={validateFormState}
                    onSubmit={onSubmit}>
                { ({touched, errors}) => (
                <Form >
                    <div className='input-group mb-2'>
                        <span className="input-group-text" id="inputGroupPrepend">
                            <i className='fa fa-user'></i>
                        </span>
                        <Field type="text" 
                               name="usercode" 
                               className="Ynfg__form-field form-control" 
                               required
                               placeholder={t('form.usercode')}/>
                        {
                            touched.usercode && <ErrorMessage name="usercode" component="div" className="invalid-feedback"/>
                        }
                    </div>
                    <div className='input-group mb-4'>
                        <span className="input-group-text" id="inputGroupPrepend">
                            <i className='fa fa-lock'></i>
                        </span>
                        <Field type="password" 
                               name="password" 
                               className="Ynfg__form-field form-control"
                               placeholder={t('form.password')}/>
                        {
                            touched.password && <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                        } 
                    </div>
                    <button type="submit" disabled={Object.keys(errors).length > 0} className='btn Ynfg__form-button'>
                        {t('form.loginSubmit')}
                    </button>
                </Form>
                )}
            </Formik>
            <a href='#'>
                <img className="Ynfg__login-logo" src={logo_img}></img>
            </a>
        </div>
    )

}