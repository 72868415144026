import { useContext, useEffect, useState } from "react"
import { UserContext } from "../core/context/UserContext"
import { useNavigate } from "react-router-dom"
import Header from "../core/components/layout/header"
import WeeklyCalendar from "../modules/calendar/components/weeklyCalendar"
import { EVENT_SERVICE } from "../modules/calendar/service/eventService"
import Event from "../modules/calendar/domain/event"

export default function Calendar(prop){

    const userCtx = useContext(UserContext)
    const navigate = useNavigate()

    function init(){
        if(!userCtx.user || !userCtx.user.token)
            navigate('/login')
    } 

    const loadEvents = (year, month) => {
        return EVENT_SERVICE.forUser(userCtx.user).getForPeriod(year, month)
    }

    const pollEvents = (year, month) => {
        return EVENT_SERVICE.forUser(userCtx.user).pollingForPeriod(year, month)
    }

    const deleteEvent = (event) => {
        return EVENT_SERVICE.forUser(userCtx.user).delete(event)
    }

    useEffect(() => {
        init()
        EVENT_SERVICE.axios.interceptors.response.use((resp) => {return resp},(error) => {
            if (error?.response?.status === 401) {
                userCtx.setUser(null)
                navigate("/login")
            }
        })
    },[]); 

    return (
        <div className='Ynfg__calendar'>
            <Header />
            <WeeklyCalendar timeslotBegin={6} 
                            timeslotEnd={22} 
                            loadFunct={loadEvents}
                            pollingFunct={pollEvents}
                            deleteFunct={deleteEvent}/>
        </div>
    )
}