import { createContext, useState } from "react";
import { useEffect } from "react";
import { loadFromStorage, loadToStorage, removeFromStorage } from "../common/utils";

export class User {
    username
    authorities
    token

    constructor(data){
        if(data){
            this.username = data['username']
            this.authorities = data['authorities']
            this.token = data['token']
        }
    }

    isAdmin(){
        return this.authorities[0] && this.authorities[0].authority === 'ROLE_ADMIN'
    }
}

export const UserContext = createContext({
        user: null,
        setUser: () => {

        }
})

export default function UserContextProvider({children}){
 
    const [currentUser, setCurrentUser] = useState(new User(loadFromStorage('user',true)))

    /**
     * each time the user is changed in the state, this must be reflect in the local 
     * storage. This because the next time the user load the page without state,
     * the initial state is loaded from the local storage
     */
    useEffect(() => {
        if(currentUser)
            loadToStorage('user', currentUser, true)
    }, [currentUser] )


    /** 
     * Tied the User context with the Provider state in order to re-render the entire
     * context each time the state change (even from its children)
     */
    const value = { 
        user: currentUser, 
        setUser: (data) => {
            if(data){
                let newUser = new User(data)
                setCurrentUser(newUser)
            }else{
                setCurrentUser(null)
                removeFromStorage('user')
            }
        }
    }


    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
