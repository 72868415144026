import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            debug: true,
            fallbackLng: 'it',
            interpolation:{
                escapeValue: false
            },
            resources: {
                it: {
                    translation: {
                        navbar: {

                        },
                        buttons: {
                            logout: 'Esci'
                        },
                        form: {
                            login: 'Modulo di Accesso',
                            usercode: 'Codice utente',
                            usercode_empty: 'Il codice utente è obbligatorio!',
                            usercode_wrong: 'Il codice utente ha un formato errato!',
                            password: 'Password',
                            usercode_empty: 'La password è obbligatoria!',
                            loginSubmit: 'Accedi',
                            reservation: 'Modulo di Prenotazione',
                            vehicleType: 'Tipologia veicolo',
                            plate: 'Targa',
                            plate_empty: 'La targa è obbligatoria!',
                            packages: 'Numero colli',
                            packages_empty: 'Il numero dei colli è obbligatorio!',
                            packages_wrong: 'Numero di colli non valido!',
                            notes: 'Note',
                            startDate: 'Data',
                            startDate_empty: 'La data è obbligatoria!',
                            load: 'Carica mezzo',
                            startTime: 'Ora inizio (hh:mm)',
                            startTime_empty: 'L\'ora inizio è obbligatoria!',
                            endTime: 'Ora fine (hh:mm)',
                            endTime_empty: 'L\'ora fine è obbligatoria!',							
                            endTime_wrong: 'Ora di fine non valida!',
                            timeRange_busy: 'Lo slot selezionato è già occupato!',
                            timeRange_boundErr: 'Lo slot selezionato non è consentito!',
                            submit: 'Salva'
                        },
                        calendar: {
                            week: 'Settimana'
                        }
                    }
                },
                en: {
                    translation: {
                        navbar: {

                        },
                        buttons: {
                            logout: 'Logout'
                        },
                        form: {
                            login: 'Login Form',
                            usercode: 'User code',
                            usercode_empty: 'User code is mandatory!',
                            usercode_wrong: 'User code is malformed.',
                            password: 'Password',
                            usercode_empty: 'Password is mandatory!',
                            loginSubmit: 'Sign in',
                            reservation: 'Reservation Form',
                            vehicleType: 'Vehicle Type',
                            plate: 'Vehicle Plate',
                            plate_empty: 'Vehicle plate is mandatory!',
                            packages: 'Packages No.',
                            packages_empty: 'Packages value is mandatory!',
                            packages_wrong: 'Packages value not supported!',
                            notes: 'Notes',
                            startDate: 'Date',
                            startDate_empty: 'Date is mandatory!',
                            load: 'Load vehicle',
                            startTime: 'Start (hh:mm)',
                            startTime_empty: 'Start time is mandatory!',
                            endTime: 'Finish (hh:mm)',
                            endTime_empty: 'Finish time is mandatory!',							
                            endTime_wrong: 'Invalid Finish time!',
                            timeRange_busy: 'Time range selected is busy!',
                            timeRange_boundErr: 'Time range is not allowed!',
                            submit: 'Save'
                        },
                        calendar: {
                            week: 'Week'
                        }
                    }
                }
            }
        }
    )

export default i18n;