export const HOURS_PORTIONS = 4
export const HOURS_PIXELS = 60

export class CalendarGridSystem{

    _h_start = 0
    _h_end = 0

    constructor(h_start, h_end){
        this._h_start = h_start
        this._h_end = h_end
    }

    getTotalHours(){
        return this._h_end - this._h_start + 1
    }

    getRowsPerDay(){
        return this.getTotalHours() * (HOURS_PORTIONS)
    }

    getTimeByRow(row){
        let hours = this._h_start + Math.floor(row / HOURS_PORTIONS)
        let extra = row % HOURS_PORTIONS
        let minutes = extra * (60 / HOURS_PORTIONS)

        let time = `${hours}:${minutes}`
        return time
    }

    gridColumn(date) {
        return date.getDay()
    }
    
    gridRow(time){
        let hour = time.toString().split(':')[0]
        let minutes = time.toString().split(':')[1]
        
        let position = 0;

        let hoursDiff = parseInt(hour) - this._h_start
        position = hoursDiff * HOURS_PORTIONS

        let minutesOffset = Math.floor(minutes / (HOURS_PIXELS / HOURS_PORTIONS))
        position = position + minutesOffset + 1

        return position
    }

    /**
     * Consider pass times of the same date.
     * Otherwise this have no sense
     * @param {*} startTime 
     * @param {*} endTime 
     */
    static durationInPixels(startTime, endTime){
        let totalMinDiff = 0
        let hourDiff = endTime.toString().split(':')[0] - startTime.toString().split(':')[0]
        let minDiff = endTime.toString().split(':')[1] - startTime.toString().split(':')[1]
        
        if(hourDiff === 0){
            if(minDiff >= 0){
                totalMinDiff = minDiff
            }else{
                throw new Error('Event pixel calcuation error! Times provided are invalid!')
            }
        }else if(hourDiff > 0){
            totalMinDiff = hourDiff * 60
            totalMinDiff = totalMinDiff + minDiff
        }else {
            throw new Error('Event pixel calcuation error! Times provided are invalid!')
        }

        let ppm = HOURS_PIXELS / 60 /**pixel per minute */
        return totalMinDiff * ppm
    }
}