//Out of here
export const loadFromStorage = (key, parse = false) => {
    if(parse)
        return JSON.parse(localStorage.getItem(key))
    else return localStorage.getItem(key)
}
export const loadToStorage = (key, data, sfy = false) => {
    if(sfy)
        localStorage.setItem(key, JSON.stringify(data))
    else localStorage.setItem(key, data)
}
export const removeFromStorage = (key) => {
    localStorage.removeItem(key)
}

export const userCodeValidator = (email) => {
    //return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) //TODO must implement validator
    return true
}
//end