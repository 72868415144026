import GenericService from "../../../core/services/genericService.js"

class VehicleService extends GenericService{

    _url = process.env.REACT_APP_BE_API_URL

    constructor({ headers }){
        super(`${process.env.REACT_APP_BE_API_URL}calendar/vehicles/`)
        if(headers)
            Array.from(headers).forEach(header => 
                this._axios.defaults.headers.common[header.name] = header.value
            )
    }

    getVehicleTypes(){  
        let url = this.appendToBaseUrl(`types`)
        return this.axios.get(url) 
    }
}

/* - - - - - - - - -*/ 
export const VEHICLE_SERVICE = new VehicleService({
    headers: null
})

