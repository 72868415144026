import { useContext, useEffect } from 'react'
import { UserContext } from '../core/context/UserContext'
import LoginForm from '../modules/user/components/loginForm'
import { useNavigate } from "react-router-dom"
import '../style/pages/login.css'

export default function Login(props){

    const userCtx = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        if(userCtx.user && userCtx.user.token)
            navigate("/")
    })

    return (
        <div className='Ynfg__login Ynfg__page-container layout-2'> 
            <main className='Ynfg__main'>
                <section className="Ynfg__login-form-container">
                    <LoginForm />
                </section>
            </main>
        </div>
    )
}