const MINUTES_PER_PACKAGES = 1.25; //1 minute and 15 secs
const MINUTES_PER_OPEN_PORTAL = 14
const MINUTES_TO_RELOAD = 40

const CONVERT_TIME_FROM_STRING = (timeStringFormat) => {
    let time = {
        hours: parseInt(timeStringFormat.toString().split(':')[0]),
        minutes: parseInt(timeStringFormat.toString().split(':')[1])
    }

    return time
}

const GET_MONDAY = (date) => {
    let _date = new Date(date)
    let day = _date.getDay()
    let diff = _date.getDate() - day + ( day == 0 ? -6 : 1 )
    _date.setHours(0,0,0,0)
    return new Date(_date.setDate(diff))
}

const GET_SUNDAY = (date) => {
    let _date = new Date(date)
    let diff = GET_MONDAY(_date).getDate() + 6
    _date.setHours(0,0,0,0)
    return new Date(_date.setDate(diff))
}

export const GET_DATE_WEEK = (date) => {
    let nw = {startDay: null, endDay: null}
    let _date = new Date(date)
    nw.startDay = GET_MONDAY(_date)
    nw.endDay = GET_SUNDAY(_date)
    return nw
}

export const GET_DATE_NEXT_WEEK = (date) => {
    let _date = new Date(date)
    _date.setDate(_date.getDate() + 7)
    return GET_DATE_WEEK(_date)
}

export const GET_PREV_WEEK = (date) => {
    let _date = new Date(date)
    _date.setDate(_date.getDate() - 7)
    return GET_DATE_WEEK(_date)
}

export const GET_WEEK_NO = (date) => {
    let _date = new Date(date)
    let _yearStart = new Date(_date.getFullYear(), 0 , 1)
    let weekNo = Math.ceil((((_date.getTime() - _yearStart.getTime()) / 86400000) + _yearStart.getDay() + 1) / 7);
    return weekNo;
}

export const WEEK_DAYS = (date) => {
    let days = []
    for(let d = GET_MONDAY(date); d <= GET_SUNDAY(date); d.setDate(d.getDate() + 1 )){
        days.push(new Date(d)) 
    }
    return days
}

export const GET_HOURS_ARRAY = (start, end) => {
    let _hs = []
    for(var h = start; h <= end; h++){
        _hs.push(h)
    }
    return _hs
}

export const GET_END_TIME = (timeStart, packages, reload = false) => {

    let minutesToAdd = Math.ceil(MINUTES_PER_PACKAGES * packages) + MINUTES_PER_OPEN_PORTAL
    if(reload === true || reload === 'true' || reload === 1 || reload === '1')
        minutesToAdd = minutesToAdd + MINUTES_TO_RELOAD 
    
    let time = CONVERT_TIME_FROM_STRING(timeStart)
    let hours = time.hours
    let minutes = time.minutes

    if((hours == null || hours == undefined) || (minutes == null || minutes == undefined)){
        throw Error('Error during time parsing. Given parameters are: ',timeStart, packages)
    }

    minutes = minutes + minutesToAdd
    if(minutes >=60){
        hours = hours + Math.floor(minutes / 60)
        minutes = minutes % 60
    }

    if(hours < 10)
        hours = "0".concat(hours.toString())
    if(minutes < 10)
        minutes = "0".concat(minutes.toString()) 

    return hours.toString().concat(':').concat(minutes)
} 

export const CONVERT_DATE_OBJ_TO_ISO_LOCAL = (date) => {
    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
    let _date = new Date(date.toString())
    let dateMs = _date.getTime();
    let dateStartLocale = new Date(dateMs - tzoffset)

    let result = new Date(dateStartLocale).toISOString().split('T')[0] 
    return result
}

export const COMPARE_TIME = (timeA, timeB) => {
    let _timeA = CONVERT_TIME_FROM_STRING(timeA) 
    let _timeB = CONVERT_TIME_FROM_STRING(timeB) 

    //start must be less than end
    if(_timeB.hours < _timeA.hours)
        return false
    if(_timeB.hours == _timeA.hours){
        return ! (_timeB.minutes < _timeA.minutes)
    }
    return true
}

/**
 * 
 * @param {MUST BE ORDERED!!!} existents 
 * @param {*} start 
 * @param {*} end 
 * @returns 
 */
export const IS_RANGE_FREE = (existents, start, end) => {
    let _existents = existents.sort((a , b) => { 
        if(COMPARE_TIME(a.start,b.start)) 
            return -1 
        else return 1
    })

    let pos = 0;
    for(var i = 0; i < _existents.length; i++){
        //compare if new start is before which 'end'
        if(!COMPARE_TIME(_existents[i].end, start)){ //less than the end
            if(!COMPARE_TIME(_existents[i].start, start)){ //before the start
                pos = i
                break;
            }else{//less than the first end but after the first start
                pos = -1
                break;
            }
        }
        if(i === (_existents.length - 1))
            pos = _existents.length
    }

    if(pos === existents.length) return true
    if(pos === -1) return false

    //the end must be before the next start
    return COMPARE_TIME(end, _existents[pos].start)

}

export const IS_BOUND_LIMITED = (timeStart, timeEnd) => {
    const FIRST_TIME_AVAILABLE = new Date().setHours(6,30)
    const LAST_TIME_AVAILABLE = new Date().setHours(21,30)

    let _timeStart = CONVERT_TIME_FROM_STRING(timeStart)
    var normalizedTimeStart = new Date().setHours(_timeStart.hours, _timeStart.minutes)

    let _timeEnd = CONVERT_TIME_FROM_STRING(timeEnd)
    var normalizedTimeEnd = new Date().setHours(_timeEnd.hours, _timeEnd.minutes)

    return (normalizedTimeStart >= FIRST_TIME_AVAILABLE && normalizedTimeEnd <= LAST_TIME_AVAILABLE)
}