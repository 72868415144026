import GenericService from "../../../core/services/genericService.js"

class EventService extends GenericService{

    _url = process.env.REACT_APP_BE_API_URL
    _userized_url = ''

    constructor({ headers }){
        super(`${process.env.REACT_APP_BE_API_URL}calendar/events/`)
        if(headers)
            Array.from(headers).forEach(header => 
                this._axios.defaults.headers.common[header.name] = header.value
            )
    }

    appendToUserizedUrl(url, option){ 
        return `${this._userized_url}${url}` 
    } 

    forUser(user){
        this._userized_url = `${this._baseUrl}${user.username}/`
        this._axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        return this
    }

    getForPeriod(year,month, before = 1, after = 6){  
        let url = this.appendToUserizedUrl(`getForPeriod/${year}/${month}?before=${before}&after=${after}`)
        return this.axios.get(url) 
    }

    pollingForPeriod(year,month, before = 1, after = 6){  
        let url = this.appendToUserizedUrl(`poll-events/${year}/${month}?before=${before}&after=${after}`)
        return this.axios.get(url) 
    }

    saveNew(dto){  
        return this.axios.post(this._userized_url, dto) 
    }

    saveExistent(dto){
        return this.axios.put(this._userized_url, dto) 
    }

    delete(dto){
        let url = this.appendToUserizedUrl(`/${dto.id}`)
        return this.axios.delete(url) 
    }
}

/* - - - - - - - - -*/ 
export const EVENT_SERVICE = new EventService({
    headers: null
})
