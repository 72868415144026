import { useEffect, useRef } from "react"
import { } from 'bootstrap/dist/js/bootstrap'

export function BModal({children, idModal, ariaLabel, title, controller}){

    return (
        <div className="modal fade" 
             data-bs-config={{backdrop:true}} 
             id={idModal} 
             tabIndex={-1} 
             aria-labelledby={ariaLabel} 
             data-bs-backdrop="static" 
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    { title && <h5 className="modal-title" id={ariaLabel}>{title}</h5> }
                    <button type="button" className="btn-close" data-bs-dismiss="modal" id="close-modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                {/*<div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>*/}
                </div>
            </div>
        </div>
    )
}