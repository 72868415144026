import GenericService from "../../../core/services/genericService.js"

class AuthService extends GenericService{

    _url = process.env.REACT_APP_BE_AUTH_URL

    constructor({ headers }){
        super(process.env.REACT_APP_BE_AUTH_URL)
        if(headers)
            Array.from(headers).forEach(header => 
                this._axios.defaults.headers.common[header.name] = header.value
            )
    }

    login(dto){  
        let url = this.appendToBaseUrl("login")
        return this.axios.post(url, dto) 
    }

}

/* - - - - - - - - -*/ 
export const AUTH_SERVICE = new AuthService({
    headers: [{
        name: 'X-Requested-With',
        value: 'XMLHttpRequest'
    }]
})

